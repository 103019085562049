@import "../../Assets/Fonts/stylesheet.css";

.hoverPopup {
	position: absolute;
  border: solid 2px rgba(46, 46, 46, 1);
  box-sizing: border-box;
  padding: 6px;
	width: 160px; 
	height: 160px;  
  background-color: white;

  display: none;
  grid-template-columns: 1fr;

  &__photo {
    width: 100%;
    object-fit: cover;
  }

  &__title {
    font-family: "Benzin";
    font-weight: 600;
    font-size: 16px;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(46, 46, 46, 1);
  }
}

@media(max-width: 500px) {
  .hoverPopup {
    width: 100px; 
    height: 100px;  
  
    &__photo {
      width: 100%;
      object-fit: cover;
      margin-bottom: 5px;
    }
  
    &__title {
      font-size: 10px;
      line-height: 10px;
    }
  }
}
