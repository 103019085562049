.footer {
  background: #F5F5F5;
  box-shadow: 0px 6.875px 10.3125px 0px rgba(151, 151, 151, 0.12);
  padding-top: 70px;
  padding-bottom: 70px;
}
.footer-wrapper {
  display: flex;
  justify-content: center;
}
.footer-name {
  display: flex;
  position: relative;
  width: 400px;
}
.footer-name__logo {
  width: 60px;
  height: 60px;
}
.footer-name__block {
  margin-left: 27px;
}
.footer-name__title {
  font-family: Benzin-Semibold, sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.footer-name__text {
  font-family: Benzin-Regular, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  width: 98%;
}
.footer-contact {
  font-family: Benzin-Semibold, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}
.footer-contact__us {
  margin-bottom: 21px;
}
.footer-contact__us-title {
  font-family: Montserrat, sans-serif;
  font-size: 17.187px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.footer-contact__us-phone, .footer-contact__us-email {
  font-family: Montserrat, sans-serif;
  font-size: 17.187px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  color: black;
}
.footer-media-wrapper {
  display: flex;
  justify-content: center;
}
.footer-media__title {
  font-family: Benzin-Semibold, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.footer-media__facebook, .footer-media__instagram, .footer-media__telegram {
  margin: 10px;
  width: 70px;
  height: 70px;
}
.footer-name, .footer-contacts, .footer-media {
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
}
.footer-web {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 80px;
}
.footer-web__item {
  font-family: Montserrat, sans-serif;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 7.5px;
}
.footer-mb {
  display: none;
}

@media (max-width: 1024px) {
  .footer {
    padding-bottom: 37px;
    padding-top: 37px;
  }
  .footer-name {
    width: 250px;
  }
  .footer-name-block {
    width: 100%;
  }
  .footer-name__title {
    font-size: 17px;
  }
  .footer-name__text {
    font-size: 8px;
  }
  .footer-contact {
    font-size: 17px;
    margin-bottom: 5px;
  }
  .footer-contact__us-title {
    font-size: 9.375px;
    margin-bottom: -5px;
  }
  .footer-contact__us-phone, .footer-contact__us-email {
    font-size: 9.273px;
  }
  .footer-media__title {
    font-size: 17px;
  }
  .footer-media__facebook, .footer-media__instagram, .footer-media__telegram {
    margin: 5px;
    width: 36px;
    height: 36px;
  }
  .footer-web__item {
    font-size: 5px;
  }
}
@media (max-width: 576px) {
  .footer-name {
    order: 0;
    width: 70%;
    justify-content: center;
    margin-bottom: 40px;
  }
  .footer-name__title {
    font-size: 24px;
  }
  .footer-name__text {
    font-size: 10px;
    margin-bottom: 0px;
  }
  .footer-name__block {
    margin-left: 14px;
  }
  .footer-wrapper {
    flex-direction: column;
  }
  .footer-web {
    display: none;
  }
  .footer-media {
    order: 1;
    width: 90%;
    justify-content: center;
    margin-bottom: 40px;
  }
  .footer-media__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .footer-media__title {
    font-size: 24px;
  }
  .footer-media__telegram, .footer-media__instagram, .footer-media__facebook {
    width: 47px;
    height: 47px;
  }
  .footer-contact {
    margin: auto;
    font-size: 24px;
    margin-bottom: 16px;
  }
  .footer-contact__us {
    margin: auto;
    margin-bottom: 10px;
  }
  .footer-contact__us-title {
    font-size: 14px;
  }
  .footer-contact__us-phone, .footer-contact__us-email {
    font-size: 14px;
  }
  .footer-contact__work {
    margin: auto;
    margin-bottom: 40px;
  }
  .footer-contacts {
    order: 2;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .footer-mb {
    order: 3;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .footer-mb__item {
    margin: auto;
    margin-bottom: 16px;
    font-family: Montserrat, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

