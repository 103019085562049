@import "../../../../Assets/Fonts/mystylesheet.css";
p {
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container__title {
  text-align: center;
  font-family: "Benzin-Semibold", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.container_boxes {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
}

.leftBox {
  width: 67%;
  height: auto;
  box-sizing: border-box;
  box-shadow: 0 6.8px 27.5px 0 rgba(0, 0, 0, 0.1);
}
.leftBox_metrics {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 30px 0 8px 30px;
}
.leftBox_metrics__metric {
  width: 50%;
  box-sizing: border-box;
  font-family: "Benzin-Regular", sans-serif;
  font-size: 17.188px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.625px;
  margin-bottom: 22px;
}
.leftBox_metrics__metric > img {
  margin-right: 11px;
}
.leftBox_metrics__metric > span {
  background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Benzin-Semibold", sans-serif;
  font-size: 17.188px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.625px;
}
.leftBox_timing {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  padding: 23px 0 17px 0;
  box-sizing: border-box;
}
.leftBox_timing > p {
  font-family: "Benzin-Regular", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.625px;
  background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.leftBox_timing > p > span {
  font-family: "Benzin-Semibold", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.625px;
}
.leftBox_timing > img {
  margin-right: 11px;
}
.leftBox_whatWeHave {
  display: flex;
  flex-direction: column;
  padding: 27px;
  margin-top: 13px;
}
.leftBox_whatWeHave__description {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.leftBox_whatWeHave_insideBoxes {
  display: flex;
  flex-direction: column;
}

.box {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}
.box__title {
  font-family: "Benzin-Semibold", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.box_contaIner {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: space-between;
}
.box_contaIner__target {
  width: 30%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin: 10px 0 10px 0;
}
.box_contaIner__target > img {
  margin-right: 5px;
}
.box_contaIner__target > p {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.box_contaIner__target > p > span {
  font-weight: 700;
}

.rightBox {
  width: 30%;
  max-width: 330px;
  height: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: sticky;
  top: 20px;
}
.rightBox__btn {
  background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%);
  width: 100%;
  height: 63px;
  flex-shrink: 0;
  color: #F5F5F5;
  font-family: "Benzin-Semibold", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  border: none;
}
.rightBox__map {
  width: 100%;
  height: 158px;
  flex-shrink: 0;
  margin-top: 27px;
  margin-bottom: 27px;
}
.rightBox_managerBox {
  width: 100%;
  height: 35%;
  flex-shrink: 0;
  background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%);
  padding: 25px;
  box-sizing: border-box;
}
.rightBox_managerBox__box {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: #F5F5F5;
  font-family: "Benzin-Semibold", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.rightBox_managerBox__box > div {
  margin-right: 12px;
  width: 54px;
  height: 54px;
  flex-shrink: 0;
  border-radius: 50%;
  background: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rightBox_managerBox__contactBoxes {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 60px;
}
.rightBox_managerBox__contactBoxes__box {
  box-sizing: border-box;
  color: #F5F5F5;
  font-family: "Benzin-Semibold", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.rightBox_managerBox__contactBoxes__box:nth-child(2) {
  margin-top: 25px;
}
.rightBox_managerBox__contactBoxes__box > img {
  margin-right: 10px;
}
.rightBox_managerBox__contactBoxes__box > a {
  text-decoration: none;
  color: #F5F5F5;
}
.rightBox_managerBox_socialMediaBox__title {
  color: #F5F5F5;
  font-family: "Benzin-Regular", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.rightBox_managerBox_socialMediaBox__links {
  display: flex;
  align-items: center;
}
.rightBox_managerBox_socialMediaBox__links > a {
  margin-right: 20px;
}
.rightBox_managerBox_socialMediaBox__links > a > div {
  border-radius: 50%;
  background: #424242;
  width: 27px;
  height: 26px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 991px) {
  .container__title {
    font-size: 24px;
  }
  .leftBox_metrics {
    padding: 15px 0 4px 15px;
  }
  .leftBox_metrics__metric {
    font-size: 9.375px;
    line-height: 11.25px;
    margin-bottom: 9px;
  }
  .leftBox_metrics__metric > img {
    margin-right: 11px;
    width: 15px;
    height: 15px;
  }
  .leftBox_metrics__metric > span {
    font-size: 9.375px;
    line-height: 11.25px;
  }
  .leftBox_timing {
    margin-top: 7px;
    padding: 12px 0 9px 0;
  }
  .leftBox_timing > p {
    font-size: 9.27px;
    line-height: 11.25px;
  }
  .leftBox_timing > p > span {
    font-size: 9.27px;
    line-height: 11.25px;
  }
  .leftBox_timing > img {
    margin-right: 11px;
  }
  .leftBox_whatWeHave {
    padding: 15px;
    margin-top: 3px;
  }
  .leftBox_whatWeHave__description {
    font-size: 9px;
    line-height: 10.97px;
  }
  .leftBox_whatWeHave_insideBoxes {
    display: flex;
    flex-direction: column;
  }
  .box {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }
  .box__title {
    font-size: 14px;
    margin: 0;
  }
  .box_contaIner__target {
    margin: 5px 0 5px 0;
  }
  .box_contaIner__target > img {
    margin-right: 5px;
    width: 13px;
    height: 13px;
  }
  .box_contaIner__target > p {
    font-size: 9px;
  }
  .box_contaIner__target > p > span {
    font-weight: 700;
  }
  .rightBox {
    width: 30%;
  }
  .rightBox__btn {
    height: 34px;
    font-size: 12px;
    line-height: 14px;
  }
  .rightBox__map {
    height: 86px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .rightBox_managerBox {
    width: 100%;
    height: 35%;
    padding: 10px;
  }
  .rightBox_managerBox__box {
    font-size: 9px;
  }
  .rightBox_managerBox__box > div {
    margin-right: 5px;
    width: 30px;
    height: 30px;
  }
  .rightBox_managerBox__box > div > img {
    width: 27px;
    height: 27px;
  }
  .rightBox_managerBox__contactBoxes {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .rightBox_managerBox__contactBoxes__box {
    font-size: 8px;
  }
  .rightBox_managerBox__contactBoxes__box:nth-child(2) {
    margin-top: 13px;
  }
  .rightBox_managerBox__contactBoxes__box > img {
    margin-right: 5px;
    width: 18px;
    height: 18px;
  }
  .rightBox_managerBox_socialMediaBox__title {
    font-size: 6px;
  }
  .rightBox_managerBox_socialMediaBox__links > a {
    margin-right: 10px;
  }
  .rightBox_managerBox_socialMediaBox__links > a > div {
    width: 15px;
    height: 15px;
  }
  .rightBox_managerBox_socialMediaBox__links > a > div > img {
    width: 8px;
    height: 8px;
  }
}
@media (max-width: 575px) {
  .rightBox {
    width: 100%;
    max-width: 345px;
    height: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
    top: 20px;
    margin-bottom: 100px;
    margin-top: 50px;
    align-self: center;
  }
  .rightBox__btn {
    background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%);
    width: 100%;
    height: 63px;
    flex-shrink: 0;
    color: #F5F5F5;
    font-family: "Benzin-Semibold", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    border: none;
  }
  .rightBox__map {
    width: 100%;
    height: 158px;
    flex-shrink: 0;
    margin-top: 27px;
    margin-bottom: 27px;
  }
  .rightBox_managerBox {
    width: 100%;
    height: 35%;
    flex-shrink: 0;
    background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%);
    padding: 25px;
    box-sizing: border-box;
  }
  .rightBox_managerBox__box {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    color: #F5F5F5;
    font-family: "Benzin-Semibold", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .rightBox_managerBox__box > div {
    margin-right: 12px;
    width: 54px;
    height: 54px;
    flex-shrink: 0;
    border-radius: 50%;
    background: #F5F5F5;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rightBox_managerBox__contactBoxes {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 60px;
  }
  .rightBox_managerBox__contactBoxes__box {
    box-sizing: border-box;
    color: #F5F5F5;
    font-family: "Benzin-Semibold", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .rightBox_managerBox__contactBoxes__box:nth-child(2) {
    margin-top: 25px;
  }
  .rightBox_managerBox__contactBoxes__box > img {
    margin-right: 10px;
  }
  .rightBox_managerBox__contactBoxes__box > a {
    text-decoration: none;
    color: #F5F5F5;
  }
  .rightBox_managerBox_socialMediaBox__title {
    color: #F5F5F5;
    font-family: "Benzin-Regular", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .rightBox_managerBox_socialMediaBox__links {
    display: flex;
    align-items: center;
  }
  .rightBox_managerBox_socialMediaBox__links > a {
    margin-right: 20px;
  }
  .rightBox_managerBox_socialMediaBox__links > a > div {
    border-radius: 50%;
    background: #424242;
    width: 27px;
    height: 26px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/*# sourceMappingURL=style.module.css.map */
