@import "../../Assets/Fonts/stylesheet.css";

.flatCard {
  all: unset;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1.5fr 1fr;
  box-shadow: 0px 6.875px 27.5px 0px rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;
  }

  &__photo {
    background-size: cover;
    background-position: center center;
  }

  &__info {
    padding: 14px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__title {
    font-family: "Benzin";
    letter-spacing: 0em;
    text-align: left;

    &_big {
      font-size: 19px;
      line-height: 22px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    &_small {
      font-size: 11px;
      line-height: 13px;
    }
  }

  &__price {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &__priceNumber {
    font-family: "Benzin";
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;

    &_small {
      font-size: 11px;
      font-weight: 500;
      line-height: 13px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}

.icons {
  display: flex;
  gap: 20px;

  &__container {
    display: flex;
    align-items: center;
    gap: 9px;
  }

  &__text {
    font-family: "Benzin";
    font-size: 8px;
    line-height: 7px;
    letter-spacing: 0em;
    text-align: left;
  }
}


@media (max-width: 970px) {
  .flatCard {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1.5fr 1fr;
    box-shadow: 0px 6.875px 27.5px 0px rgba(0, 0, 0, 0.1);
  
    &__photo {
      background-size: cover;
      background-position: center center;
    }
  
    &__info {
      padding: 14px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  
    &__title {
      font-family: "Benzin";
      letter-spacing: 0em;
      text-align: left;
  
      &_big {
        font-size: 14px;
        line-height: 13px;
      }
  
      &_small {
        font-size: 10px;
        line-height: 7px;
      }
    }
  
    &__price {
      display: flex;
      align-items: center;
      gap: 6px;
    }
  
    &__priceNumber {
      font-family: "Benzin";
      font-weight: 600;
      font-size: 12px;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
  
      &_small {
        font-size: 8px;
        font-weight: 500;
        line-height: 13px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
  
  .icons {
    display: flex;
    justify-content: center;
    gap: 20px;
  
    &__container {
      display: flex;
      align-items: center;
      gap: 9px;
    }
  
    &__text {
      font-size: 8px;
      line-height: 10px;
    }

    &__icon {
      width: 14px;
    }
  }
}

@media (max-width: 570px) {
  .flatCard {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1.5fr 1fr;
    box-shadow: 0px 6.875px 27.5px 0px rgba(0, 0, 0, 0.1);
  
    &__photo {
      background-size: cover;
      background-position: center center;
    }
  
    &__info {
      padding: 14px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  
    &__title {
      font-family: "Benzin";
      letter-spacing: 0em;
      text-align: left;
  
      &_big {
        font-size: 19px;
        line-height: 22px;
      }
  
      &_small {
        font-size: 11px;
        line-height: 13px;
      }
    }
  
    &__price {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    &__icon {
      width: 16px;
    }
  
    &__priceNumber {
      font-size: 16px;
      line-height: 19px;

      &_small {
        font-size: 11px;
        line-height: 12px;
      }
    }
  }
  
  .icons {
    display: flex;
    justify-content: center;
    gap: 20px;
  
    &__container {
      display: flex;
      align-items: center;
      gap: 9px;
    }

    &__icon {
      width: 16px;
    }
  
    &__text {
      font-size: 10px;
      line-height: 12px;
    }
  }
  
}