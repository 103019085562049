.reviews
  margin-bottom: 150px

.testimonials-info
  display: flex
  justify-content: flex-start
  align-items: center

.testimonials-img
  box-sizing: border-box
  padding-left: 0px
  padding-right: 18px

.testimonials-img__icon
  width: 81px
  height: 81px
  border-radius: 50px

.testimonials-text
  font-family: Montserrat
  font-size: 18.411px
  font-style: normal
  font-weight: 500
  line-height: normal

  background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%)
  background-clip: text
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent
  
.testimonials-name
  font-family: Benzin-Semibold
  font-size: 20.163px
  font-style: normal
  font-weight: 400
  line-height: normal

.testimonials-proffesion
  font-family: Benzin-Semibold
  font-size: 16.109px
  font-style: normal
  font-weight: 400
  line-height: normal
  
.rules__title
  font-family: Benzin-Semibold
  font-weight: 600
  font-size: 36px
  line-height: 52px
  letter-spacing: 0em
  text-align: center
  margin-bottom: 28px

.sliderArrow
  &-next
    position: absolute
    top: 35%
    right: -4%
    cursor: pointer

  &-prev
    position: absolute
    top: 35%
    left: -4%
    cursor: pointer

//.slick-active:nth-of-type(2)
//  transform: scale(1.08)!important

.slide
  opacity: .5!important
  transform: scale(.7)!important

.slide-active
  opacity: 1!important
  transform: scale(1)!important

.slick-slider
  margin-bottom: 0px
  position: relative

//.center:nth-of-type(2)
//  transform: scale(1.08)!important


.slick-slide > div
  margin: 0 10px
  display: flex
  flex-direction: column
  align-items: center

.slick-list
  margin: 0 -10px

.slick-dots li.slick-active button:before
  width: 44px !important
  height: 44px !important
  background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%)
  content: ''
  border-radius: 50%

.slick-dots li button::before
  width: 25px !important
  height: 25px !important
  background: linear-gradient(180deg, #a8a7a7 0%, #a8a7a7 100%)
  content: ''
  border-radius: 50%
  opacity: 1 !important

.slick-dots li button:before
  color: #a8a7a7!important

.slick-dots
  display: flex !important
  justify-content: center !important
  align-items: center !important
  bottom: -80px !important

  > li
    width: 25px !important
    height: 25px !important
    margin: 0 20px !important

  > li.slick-active
    width: 44px !important
    height: 44px !important

@media (max-width: 1024px)
  .mainArticle
    font-size: 68.07px
    font-weight: 700
    line-height: 84.26px
    margin-left: 8px
    margin-bottom: 60px

  .slide
    opacity: 1!important
    transform: scale(1)!important

  .slide-active
    opacity: 1!important
    transform: scale(1)!important

  .sliderBox
    &__article
      font-size: 51.6px
      line-height: 51.6px

    &__description
      font-size: 25.8px
      line-height: 30.96px

    &-country

      &__country
        font-size: 25.8px
        line-height: 25.8px

      &__arrow

      &__ukr
        font-size: 25.8px
        line-height: 25.8px

  .sliderArrow
    &-next
      width: 67px
      height: 67px
      position: absolute
      top: 109%
      right: 3%
      cursor: pointer
      z-index: 1

    &-prev
      width: 67px
      height: 67px
      position: absolute
      top: 109%
      left: 3%
      cursor: pointer
      z-index: 1

@media (max-width: 600px)
  .rules__title
    font-family: Benzin-Semibold
    font-weight: 500
    font-size: 36px
    line-height: 52px
    letter-spacing: 0em
    text-align: center
    margin-bottom: 0px

  .testimonials-img__icon
    width: 60px
    height: 60px
    border-radius: 50px

  .testimonials-img
    box-sizing: border-box
    padding-left: 0px
    padding-right: 18px

  .testimonials-text
    font-family: Montserrat
    font-size: 12.411px
    font-style: normal
    font-weight: 500
    line-height: normal

  .testimonials-name
    font-family: Benzin-Semibold
    font-size: 18.163px
    font-style: normal
    font-weight: 400
    line-height: normal

  .testimonials-proffesion
    font-family: Benzin-Semibold
    font-size: 12.109px
    font-style: normal
    font-weight: 400
    line-height: normal

  .mainArticle
    font-size: 40px
    font-weight: 700
    line-height: 49.52px
    margin-left: 8px
    margin-bottom: 40px

  .sliderBox
    &__article
      font-size: 24px
      line-height: 24px

    &__description
      font-size: 20px
      line-height: 20px

    &-country

      &__country
        font-size: 16px
        line-height: 16px

      &__arrow


      &__ukr
        font-size: 16px
        line-height: 16px

  .sliderArrow
    &-next
      width: 47px
      height: 47px
      position: absolute
      top: 111.2%
      right: 3%
      cursor: pointer
      z-index: 1

    &-prev
      width: 47px
      height: 47px
      position: absolute
      top: 111.2%
      left: 3%
      cursor: pointer
      z-index: 1

  .slick-dots li.slick-active button:before
    width: 31.58px !important
    height: 31.58px !important
    background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%)
    content: ''
    border-radius: 50%

  .slick-dots li button::before
    width: 17.76px !important
    height: 17.76px !important
    background: linear-gradient(180deg, #a8a7a7 0%, #a8a7a7 100%)
    content: ''
    border-radius: 50%
    opacity: 1 !important

  .slick-dots li button:before
    color: #a8a7a7!important

  .slick-dots
    display: flex !important
    justify-content: center !important
    align-items: center !important
    bottom: -62px !important

    > li
      width: 17.76px !important
      height: 17.76px !important
      margin: 0 10px !important

    > li.slick-active
      width: 31.58px !important
      height: 31.58px !important

.slick-slider
  margin-bottom: 0px !important
