@import "../../../Assets/Fonts/stylesheet.css"

.back-hat
    display: flex
    align-items: center
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    width: 100vw
    height: 700px
    margin-bottom: 150px

.hat-content
    display: flex
    flex-direction: column

.hat-heading__text
    margin-bottom: 40px

.hat-text
    font-family: "Benzin"
    font-size: 60px
    font-style: normal
    font-weight: 600
    line-height: 80px

.hat-logo__info
    display: flex
    justify-content: space-between

    width: 559px
    height: 157px
    flex-shrink: 0

    padding: 20px
    box-sizing: border-box

    background: linear-gradient(180deg, rgba(59, 59, 59, 0.50) 0%, rgba(46, 46, 46, 0.50) 100%)
    backdrop-filter: blur(20px)

.hat-info
    display: flex
    flex-direction: column
    align-items: center

.hat-logo
    width: 58px
    height: 58px
    margin-bottom: 15px

.hat-logo__text
    color: #FFF
    text-align: center
    font-family: Montserrat
    font-size: 16.667px
    font-style: normal
    font-weight: 500
    line-height: normal
    margin: 0px


@media (max-width: 576px)
    .back-hat
        background-image: url("../../../Assets/Images/mobile_gk.png")
        display: flex
        align-items: center
        background-size: cover
        background-position: center
        background-repeat: no-repeat
        width: 100vw
        height: 550px
        margin-bottom: 60px

    .hat-heading__text
        margin-bottom: 230px

    .hat-text
        font-family: "Benzin"
        font-size: 40px
        font-style: normal
        font-weight: 600
        line-height: 40px

    .hat-logo__info
        display: flex
        justify-content: space-between

        width: 100%
        height: 90px
        flex-shrink: 0

        padding: 11px
        box-sizing: border-box

        background: linear-gradient(180deg, rgba(59, 59, 59, 0.50) 0%, rgba(46, 46, 46, 0.50) 100%)
        backdrop-filter: blur(20px)

    .hat-info
        display: flex
        flex-direction: column
        align-items: center

    .hat-logo
        width: 33px
        height: 33px
        margin-bottom: 15px

    .hat-logo__text
        color: #FFF
        text-align: center
        font-family: Montserrat
        font-size: 10px
        font-style: normal
        font-weight: 500
        line-height: normal
        margin: 0px
