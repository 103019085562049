.header
  background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%)
  &-wrapper
    display: flex
    position: relative
    height: 74px
    align-items: center
    justify-content: space-between
  &-firstblock
    display: flex
    justify-content: space-evenly
    width: 100%
  &-phone
    display: none
  &-logo
    width: 53px
    height: 53px
    margin: 10px
  &-navigation
    display: flex
    align-items: center
    justify-content: space-evenly
    width: 100%
    margin-left: 10%
    &__item
      text-decoration: none
      color: #F5F5F5
      font-family: Benzin-Semibold, sans-serif
      font-size: 13px
      font-style: normal
      font-weight: 400
      line-height: normal
@media (max-width: 1024px)
  .header
    &-wrapper
      height: 40px
    &-firstblock
      justify-content: flex-start
    &-navigation
      &__item
        color: #F5F5F5
        font-family: Benzin-Semibold, sans-serif
        font-size: 7px
        font-style: normal
        font-weight: 400
        line-height: normal
    &-logo
      width: 40px
      height: 40px

@media (max-width: 576px)
  .header
    height: 81px
    &-navigation
      display: none
    &-phone
      display: flex
      position: absolute
      top: 37%
      right: 48%
      color: #FFF
      font-family: Montserrat, sans-serif
      font-size: 16px
      font-style: normal
      font-weight: 400
      line-height: normal
      transform: translateX(50%)
    &-wrapper
      height: 81px
    &-logo
      width: 53px
      height: 53px

