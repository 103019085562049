.footer
  background: #F5F5F5
  box-shadow: 0px 6.875px 10.3125px 0px rgba(151, 151, 151, 0.12)
  padding-top: 70px
  padding-bottom: 70px

  &-wrapper
    display: flex
    justify-content: center

  &-name
    display: flex
    position: relative
    width: 400px
    &__logo
      width: 60px
      height: 60px

    &__block
      margin-left: 27px

    &__title
      font-family: Benzin-Semibold, sans-serif
      font-size: 35px
      font-style: normal
      font-weight: 400
      line-height: normal

    &__text
      font-family: Benzin-Regular, sans-serif
      font-size: 14px
      font-style: normal
      font-weight: 400
      line-height: 19px
      width: 98%

  &-contact
    font-family: Benzin-Semibold, sans-serif
    font-size: 32px
    font-style: normal
    font-weight: 400
    line-height: normal
    margin-bottom: 8px

    &__us
      margin-bottom: 21px

      &-title
        font-family: Montserrat, sans-serif
        font-size: 17.187px
        font-style: normal
        font-weight: 700
        line-height: normal

      &-phone, &-email
        font-family: Montserrat, sans-serif
        font-size: 17.187px
        font-style: normal
        font-weight: 400
        line-height: normal
        text-decoration: none
        color: black

  &-media
    &-wrapper
      display: flex
      justify-content: center

    &__title
      font-family: Benzin-Semibold, sans-serif
      font-size: 32px
      font-style: normal
      font-weight: 400
      line-height: normal
      text-align: center

    &__facebook, &__instagram, &__telegram
      margin: 10px
      width: 70px
      height: 70px

  &-name, &-contacts, &-media
    margin: auto
    margin-top: 0
    margin-bottom: 0


  &-web
    display: flex
    position: absolute
    bottom: 0
    left: 80px

    &__item
      font-family: Montserrat, sans-serif
      font-size: 9px
      font-style: normal
      font-weight: 400
      line-height: normal
      margin: 7.5px

  &-mb
    display: none

@media (max-width: 1024px)
  .footer
    padding-bottom: 37px
    padding-top: 37px
    &-name
      width: 250px
      &-block
        width: 100%
      &__title
        font-size: 17px

      &__text
        font-size: 8px

    &-contact
      font-size: 17px
      margin-bottom: 5px
      &__us
        &-title
          font-size: 9.375px
          margin-bottom: -5px

        &-phone, &-email
          font-size: 9.273px

    &-media
      &__title
        font-size: 17px

      &__facebook, &__instagram, &__telegram
        margin: 5px
        width: 36px
        height: 36px
    &-web
      &__item
          font-size: 5px

@media (max-width: 576px)
  .footer
    &-name
      order: 0
      width: 70%
      justify-content: center
      margin-bottom: 40px
      &__title
        font-size: 24px
      &__text
        font-size: 10px
        margin-bottom: 0px
      &__block
        margin-left: 14px
    &-wrapper
      flex-direction: column

    &-web
      display: none

    &-media
      order: 1
      width: 90%
      justify-content: center
      margin-bottom: 40px
      &__wrapper
        display: flex
        align-items: center
        justify-content: space-evenly
      &__title
        font-size: 24px
      &__telegram,&__instagram,&__facebook
        width: 47px
        height: 47px
    &-contact
      margin: auto
      font-size: 24px
      margin-bottom: 16px
      &__us
        margin: auto
        margin-bottom: 10px
        &-title
          font-size: 14px
        &-phone, &-email
          font-size: 14px
      &__work
        margin: auto
        margin-bottom: 40px
    &-contacts
      order: 2
      width: 90%
      display: flex
      flex-direction: column
      justify-content: center
    &-mb
      order: 3
      display: flex
      justify-content: center
      flex-direction: column
      &__item
        margin: auto
        margin-bottom: 16px
        font-family: Montserrat, sans-serif
        font-size: 10px
        font-style: normal
        font-weight: 400
        line-height: normal