@import "../../../../Assets/Fonts/mystylesheet.css";
p {
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  position: relative;
}
.container_metrics {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 12px 60px 5px 13px;
  background: rgb(245, 245, 245);
  position: absolute;
  top: -100px;
}
.container_metrics__metric {
  width: 50%;
  box-sizing: border-box;
  font-family: "Benzin-Regular", sans-serif;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 9px;
}
.container_metrics__metric > img {
  margin-right: 6px;
  width: 13px;
  height: 13px;
}
.container_metrics__metric > span {
  background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Benzin-Semibold", sans-serif;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.container_timing {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
  padding: 8px 53px;
  box-sizing: border-box;
  background: rgb(245, 245, 245);
  position: absolute;
  top: -40px;
}
.container_timing > p {
  font-family: "Benzin-Regular", sans-serif;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 9.6px;
  background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.container_timing > p > span {
  font-family: "Benzin-Semibold", sans-serif;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.625px;
}
.container_timing > img {
  margin-right: 11px;
  width: 13px;
  height: 13px;
}
.container_btn {
  width: 100%;
  margin-top: 25px;
}
.container_btn__btn {
  background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%);
  width: 100%;
  height: 61px;
  flex-shrink: 0;
  color: #F5F5F5;
  font-family: "Benzin-Semibold", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  border: none;
}
.container_whatWeHave {
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin-top: 20px;
  background: rgb(245, 245, 245);
}
.container_whatWeHave_insideBoxes {
  display: flex;
  flex-direction: column;
}

.box {
  display: flex;
  flex-direction: column;
  margin-top: 21px;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.box__title {
  font-family: "Benzin-Semibold", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.box_contaIner {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: space-between;
}
.box_contaIner__target {
  width: 30%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin: 3px 0 3px 0;
}
.box_contaIner__target > img {
  margin-right: 5px;
  width: 12px;
  height: 12px;
}
.box_contaIner__target > p {
  font-family: "Montserrat", sans-serif;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.box_contaIner__target > p > span {
  font-weight: 700;
}

/*# sourceMappingURL=styles.module.css.map */
