@import "../../../Assets/Fonts/stylesheet.css";

.houses {
  min-height: 70vh;
  background-color: white;

  display: flex;
  flex-direction: column;
  gap: 100px;
}

@media(max-width:770px) {
  .houses {
    display: flex;
    flex-direction: column;
    gap: 100px;
  }
}
@media(max-width:500px) {
  .houses {
    gap: 60px;
  }
}


.houseCard {
  display: grid;
  grid-template-columns: 44% auto;
  gap: 60px;

  img {
    user-select: none;
  }

  &_invert {
    grid-template-columns: auto 44%;
  }

  height: auto;

  &__photo {
    width: 100%;
    height: inherit;
    object-fit: cover;

    &_invert {
      grid-column: 2/3;
    }
  }

  &__info {
    display: grid;
    grid-template-columns: 1fr;
    align-items: space-between;

    &_invert {
      grid-column: 1/2;
      grid-row: 1;
    }
  }

  &__title {
    font-family: "Benzin";
    font-weight: 600;
    font-size: 64px;
    line-height: 75px;
    letter-spacing: 0em;
    text-align: left;
    font-size: 44px;
    line-height: 52px;
  }

  &__description {
    font-family: "Montserrat";
    letter-spacing: 0em;
    text-align: left;
    font-size: 17px;
    font-weight: 500;
    line-height: 21px;

  }

  &__location {
    font-family: "Benzin";
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 0em;
    display: flex;
    gap: 25px;
    align-items: center;
    font-size: 25px;
  }

  &__btn {
    display: inline-block;
    font-family: "Benzin";
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    padding: 20px 28px;

    font-size: 19px;
    line-height: 26px;
    background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%);

    align-self: flex-end;
    justify-self: flex-start;
    user-select: none;

    &:hover {
      cursor: pointer;
    }
  }
}

@media (max-width: 990px) {
  .houseCard {
    gap: 30px;
    height: auto;
  }
}


@media (max-width: 770px) {
  .houseCard {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-bottom: 50px;

    img {
      user-select: none;
    }

    &_invert {
      grid-template-columns: 1fr;
    }

    height: auto;

    &__photo {
      width: 100%;
      height: inherit;
      object-fit: cover;

      &_invert {
        grid-column: auto;
      }
    }

    &__info {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;

      &_invert {
        grid-column: auto;
        grid-row: auto;
      }
    }

    &__title {
      font-size: 36px;
      line-height: 42px;
    }

    &__description {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }

    &__location {
      font-size: 16px;
      line-height: 19px;

      align-items: flex-end;

      img {
        width: 18px;
      }
    }

    &__btn {
      display: block;
      width: 100%;
      text-align: center;
      justify-self: center;
      padding: 18px 18px;
      font-size: 19px;
      line-height: 25px;
    }
  }
}
