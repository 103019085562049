.header {
  background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%);
}
.header-wrapper {
  display: flex;
  position: relative;
  height: 74px;
  align-items: center;
  justify-content: space-between;
}
.header-firstblock {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.header-phone {
  display: none;
}
.header-logo {
  width: 53px;
  height: 53px;
  margin: 10px;
}
.header-navigation {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-left: 10%;
}
.header-navigation__item {
  text-decoration: none;
  color: #F5F5F5;
  font-family: Benzin-Semibold, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 1024px) {
  .header-wrapper {
    height: 40px;
  }
  .header-firstblock {
    justify-content: flex-start;
  }
  .header-navigation__item {
    color: #F5F5F5;
    font-family: Benzin-Semibold, sans-serif;
    font-size: 7px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .header-logo {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 576px) {
  .header {
    height: 81px;
  }
  .header-navigation {
    display: none;
  }
  .header-phone {
    display: flex;
    position: absolute;
    top: 37%;
    right: 48%;
    color: #FFF;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transform: translateX(50%);
  }
  .header-wrapper {
    height: 81px;
  }
  .header-logo {
    width: 53px;
    height: 53px;
  }
}

