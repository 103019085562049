.mobile-navigation {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mobile-navigation__item {
  color: #F5F5F5;
  font-family: Benzin-Semibold, sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  margin: 10px;
}

.burger-button {
  text-align: center !important;
  background: var(--7, linear-gradient(180deg, #3B3B3B 0%, #2E2E2E 100%));
  margin: auto !important;
  height: 3px !important;
  width: 22px !important;
  transform-origin: -3.5px !important;
}

.overlay {
  background: #000;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}

.sc-grXZZQ {
  width: 30px !important;
  height: 30px !important;
  right: 20px !important;
  background: #FFF !important;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1) !important;
}

.cGZbtF {
  padding-left: 8px !important;
}

