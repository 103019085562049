.houses {
  min-height: 100vh;
  background-color: white;

  display: flex;
  flex-direction: column;
  gap: 100px;
}

@media(max-width:770px) {
  .houses {
    display: flex;
    flex-direction: column;
    gap: 100px;
  }
}
@media(max-width:500px) {
  .houses {
    gap: 60px;
  }
}
