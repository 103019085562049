@import "../../../Assets/Fonts/stylesheet.css";

.flats {

  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  margin-bottom: 150px;

  &__title {
    font-family: "Benzin";
    font-weight: 600;
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 28px;
  }

  &__flats {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 31px;
    row-gap: 39px;
  }
}

@media (max-width: 970px) {
  .flats {

    &__title {
      font-size: 24px;
      line-height: 28px;
    }

    &__flats {
      grid-template-columns: 1fr 1fr;
      column-gap: 31px;
      row-gap: 39px;
    }
  } 
}

@media (max-width: 570px) {
  .flats {

    margin-bottom: 60px;

    &__title {
      font-size: 24px;
      font-size: 36px;
      line-height: 42px;
    }

    &__flats {
      grid-template-columns: 1fr;
      column-gap: 31px;
      row-gap: 39px;
    }
  } 
}
