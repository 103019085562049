.gkSlider {
  margin-top: 150px;
  margin-top: 150px;
}

.gkSlider .slick-dots .slick-active img {
  border: 4px solid black;
}

.gkSlider .slick-dots img {
  box-sizing: border-box;
}


.gkSlider .box {
  margin-top: 104px;
}

.gkSlider .dot {
  width: 136px;
  height: 136px;
}

.gkSlider .photoCard {
  width: 100%;
  height: 495px;

  object-fit: contain;
  
}

.gkSlider .slick-list {
  width: 100% !important;
  height: auto !important;
}

.gkSlider .slick-dots {
  display: grid !important;
  grid-template-columns: repeat(8, 1fr);
  row-gap: 25px;
  align-items: center !important;
  justify-content: flex-start !important;
  width: 100% !important;
  position: static !important;
  margin-top: 50px;
  height: auto !important;
}

.gkSlider .slick-dots > li {
  width: 130px !important;
  height: 130px !important;
  /* margin: 10px 15px 10px 0 !important; */
}

.gkSlider .slick-prev, .gkSlider .slick-next {
  cursor: pointer !important;
  border-radius: 50% !important;
  width: 48px !important;
  height: 48px !important;
  top: 250px !important;
}

.gkSlider .slick-prev {
  left: 0 !important;
  z-index: 1 !important;
}

.gkSlider .slick-next {
  right: 0 !important;
  z-index: 1 !important;
}

@media (max-width: 1300px) {
  .gkSlider .slick-dots {
    grid-template-columns: repeat(6, 1fr);
  }
  .gkSlider .slick-dots > li {
    justify-self: center !important;
  }
}

@media (max-width: 991px) {
  .gkSlider {
    margin-top: 150px;
    margin-top: 100px;
  }

  .gkSlider .photoCard {
    width: 100%;
    height: 495px;
  
    object-fit: cover !important;
  }

  .gkSlider .slick-dots {
    grid-template-columns: repeat(4, 1fr);
  }

  .gkSlider .slick-dots > li {
    /* width: 74px !important; */
    height: 74px !important;
    /* margin: 7px 10px 7px 0 !important; */
  }

  .gkSlider .dot {
    width: 74px !important;
    height: 74px !important;
  }
}
@media (max-width: 575px) {
  .gkSlider .slick-dots > li {
    width: 60px !important;
    height: 60px !important;
    margin: 4px 10px 4px 0 !important;
  }
  .gkSlider .dot {
    width: 60px !important;
    height: 60px !important;
  }
}

/*# sourceMappingURL=stylesSlider.css.map */
