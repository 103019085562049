@import "../../../Assets/Fonts/stylesheet.css";

.hat {
  min-height: 150vh;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  

  &__info {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(269.88deg, rgba(0, 0, 0, 0) 9.63%, rgba(0, 0, 0, 0.5) 99.91%);
  }

  &__title {
    color: white;
    font-family: 'Benzin';
    font-weight: 600;
    font-size: 41px;
    line-height: 55px;
    letter-spacing: 0em;
    text-align: left;

  }

  &__infoContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    max-height: 100vh;
  }
}

.mpIcons {
  display: flex;
  justify-content: space-around;
  width: 37%;
  padding-top: 25px;
  padding-bottom: 13px;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  position: relative;

  border-top: 1px solid white;
  border-bottom: 1px solid white;


  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 27.08%, rgba(255, 255, 255, 0) 76.04%, #FFFFFF 100%);
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }



  &__container {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
  }

  &__icon {
    justify-self: center;
    margin-bottom: 10px;
  }

  &__text {
    text-align: center;
    font-family: "Montserrat";
    font-size: 17px;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: center;


    color: white;
  }
}

@media(max-width: 1000px) {
  .mpIcons {
    width: 57%;
  }
}

@media(max-width: 770px) {
  .mpIcons {
    width: 90%;
  }
}

@media(max-width: 500px) {
  .hat {
    min-height: 120vh;
  
    &__title {
      font-size: 40px;
      line-height: 40px;
    }
  }
  .mpIcons {
    padding: 10px;
    width: 100%;
  
    &__icon {
      width: 30px;
    }
  
    &__text {
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
    }
  }
}
