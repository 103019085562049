.mpMap {
  // min-height: 50vh;
  height: 500px;
  transform: translateY(-45%);

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgb(255, 255, 255) 21%);
  position: relative;

  display: flex;
  justify-content: center;

  &__clouds {
    background-image: url(../../../Assets/Images/clouds.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    transform: translateY(-70%);

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__map {
    position: absolute;
    transform: translateY(-70%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: grid;
    grid-template-columns: 1fr;


  }

  &__svg {
    z-index: 3;
    width: 55%;
    margin: 0 auto;
  }

  margin-bottom: 100px;
}


@media(max-width: 1900px) {
  .mpMap {
    height: 400px;
  }
}

@media(max-width: 1350px) {
  .mpMap {
    height: 300px;
  }
}

@media(max-width: 1040px) {
  .mpMap {
    height: 250px;
  }
}

@media(max-width: 990px) {
  .mpMap {
    height: 250px;

    &__svg {
      z-index: 3;
      width: 70%;
      margin: 0 auto;
    }
  }
}

@media(max-width: 770px) {
  .mpMap {
    height: 250px;

    &__svg {
      z-index: 3;
      width: 90%;
      margin: 0 auto;
    }
  }
}

@media(max-width: 500px) {
  .mpMap {
    height: 150px;
    text-align: center;
    font-family: "Benzin";
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color: black;

    &__svg {
      z-index: 3;
      width: 100%;
      margin: 0 auto;
    }
  }
}
