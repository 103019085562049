.wrap {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 150px;
}

.title {
  text-align: center;
  font-family: "Benzin-Semibold", sans-serif;
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn {
  width: 502px;
  height: 96px;
  flex-shrink: 0;
  background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%);
  color: #F5F5F5;
  text-align: center;
  font-family: "Benzin-Semibold", sans-serif;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: 39px;
  margin-top: 13px;
}

@media (max-width: 991px) {
  .wrap {
    margin-top: 57px;
    margin-bottom: 60px;
  }
  .title {
    font-size: 30px;
  }
  .btn {
    width: 274px;
    height: 52px;
    font-size: 18px;
    line-height: 22px;
    margin-top: 8px;
  }
}
@media (max-width: 575px) {
  .wrap {
    margin-top: 65px;
    margin-bottom: 65px;
  }
  .title {
    font-size: 36px;
  }
  .btn {
    width: 320px;
    height: 61px;
    font-size: 19px;
    line-height: 25px;
    margin-top: 16px;
  }
}

/*# sourceMappingURL=style.module.css.map */
