.rules
    margin-bottom: 150px

.rules__title
    font-family: "Benzin"
    font-weight: 600
    font-size: 36px
    line-height: 52px
    letter-spacing: 0em
    text-align: center
    margin-bottom: 28px

.rules-heading__text
    margin-bottom: 40px

.rules-text
    font-family: "Benzin"
    font-size: 60px
    font-style: normal
    font-weight: 600
    line-height: 80px

.rules-logo__info
    display: flex
    justify-content: space-between

    width: 100%
    height: auto
    flex-shrink: 0

    padding: 20px
    box-sizing: border-box


.rules-info
    display: flex
    flex-direction: column
    align-items: center
    width: 33%

.rules-logo
    width: 127px
    height: 127px
    margin-bottom: 15px

.rules-logo__text
    text-align: center
    font-family: Montserrat
    font-size: 20px
    font-style: normal
    font-weight: 500
    line-height: normal
    margin: 0px

@media (max-width: 576px)

    .rules
        margin-bottom: 60px

    .rules__title
        font-family: "Benzin"
        font-weight: 500
        font-size: 36px
        line-height: 52px
        letter-spacing: 0em
        text-align: center
        margin-bottom: 0px

    .rules-logo__info
        display: flex
        flex-direction: column
        align-items: center
        justify-content: space-between

        width: 100%
        height: auto
        flex-shrink: 0

        padding: 20px
        box-sizing: border-box

    .rules-info
        display: flex
        flex-direction: column
        align-items: center
        width: 100%
        margin-bottom: 40px

    .rules-logo
        width: 75px
        height: 75px
        margin-bottom: 12px

    .rules-logo__text
        text-align: center
        font-family: Montserrat
        font-size: 14px
        font-style: normal
        font-weight: 500
        line-height: normal
        margin: 0px
