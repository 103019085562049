.tutoring-form
  width: 610px

.form
  display: flex
  justify-content: center
  align-items: center
  padding-bottom: 69px
  &-wrapper
    background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%)
  &-title
    color: #F5F5F5
    text-align: center
    font-family: Benzin-Semibold
    font-size: 44px
    font-style: normal
    font-weight: 400
    line-height: normal
    padding-top: 70px
    margin-bottom: 11px
  &-text
    color: #F5F5F5
    font-family: Montserrat
    font-size: 16px
    font-style: normal
    font-weight: 500
    line-height: normal
    text-align: center
    margin-bottom: 70px
  &-control
    width: 100% !important
    height: 69px !important
    border-radius: 0px !important
    flex-shrink: 0
    margin-right: 20px !important
    margin: 0 !important
    border: none !important
    font-family: Benzin-Regular
    font-size: 21px !important
    font-style: normal
    font-weight: 400
    line-height: 26px
    color: black !important


.react-tel-input
  margin-left: 20px
  width: 330px !important
  height: 69px !important
.form-control::placeholder
  color: black !important
.flag-dropdown
  top: 6px !important
  left: 2px !important
  background-color: inherit !important
.flag-dropdown
  border: inherit !important
  width: 30% !important
  height: 80%

.send
  &-form
    width: 330px
    height: 69px
    box-sizing: border-box
    margin-left: 20px
    margin-right: 20px
    font-family: Benzin-Regular
    font-size: 21px
    font-style: normal
    font-weight: 400
    line-height: 26px
    color: white
    border: 1px solid white
    background: linear-gradient(180deg, #2B2B2B 0%, #121212 100%)
    &:hover
      background-color: transparent
      border: 1px solid white
      box-sizing: border-box
      color: white
      transition: .2s
      transform: scale(1.01)



.name
  border: none
  margin-left: 20px
  padding-left: 20px
  padding-right: 20px
  width: 330px
  height: 69px
input, input::placeholder
  font-family: Benzin-Regular
  font-size: 21px
  font-style: normal
  font-weight: 400
  line-height: 26px
  color: black
@media (max-width: 1024px)
  .form
    padding-bottom: 39px
    &-title
      font-size: 24px
      padding-top: 37px
      margin-bottom: 6px
    &-text
      font-size: 9px
      margin-bottom: 37px
    &-control, &-control::placeholder
      font-size: 11px!important
      line-height: 14px!important
      height: 34px !important
    input, .name, .send-form
      font-size: 11px
      line-height: 14px
      width: 25%
      height: 34px
    .name::placeholder
      font-size: 11px
      line-height: 14px
    .react-tel-input
      margin-left: 20px
      width: 25% !important
      height: 34px !important
    .flag-dropdown
      top: 3px !important
      left: 3px !important
@media (max-width: 576px)
  .form
    flex-direction: column
    padding-bottom: 39px
    &-title
      font-size: 36px
      line-height: 36px
      padding-top: 37px
      margin-bottom: 16px
    &-text
      font-size: 16px
      margin-bottom: 37px
    &-control, &-control::placeholder
      font-size: 20px!important
      line-height: 25px!important
      height: 61px !important
      margin: auto !important
    input, .name, .send-form
      font-size: 20px
      line-height: 25px
      width: 90%
      height: 61px
      margin: auto
      margin-bottom: 24px
    .name::placeholder
      font-size: 20px
      line-height: 25px
    .react-tel-input
      margin: auto !important
      width: 90% !important
      height: 61px !important
      margin-bottom: 24px !important
